import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { CustomIconService } from './custom-icons.service';

export function provideRmaIcon(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: (iconService: CustomIconService) => () => iconService.register(),
      deps: [CustomIconService],
      multi: true,
    },
  ]);
}
