import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { rmaIcons } from '../domain/rma-icons.const';

@Injectable({ providedIn: 'root' })
export class CustomIconService {
  public static svgIcons = rmaIcons;

  public constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly rmaUrls: RmaUrlsService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  public register(): void {
    const icons = [...rmaIcons];
    icons.forEach((icon) =>
      this.iconRegistry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(this.rmaUrls.imageUrl(icon.location))),
    );
  }
}
